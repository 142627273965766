<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="pt-10" elevation="0">
            <v-card-text class="text-center">
              <v-img
                :src="require('@/assets/Text-Color.svg')"
                contain
                width="163"
                class="ma-auto"
              ></v-img>

              <div class="pt-2">
                <h4 class="text-h5 primary--text">{{ $t("signin.title") }}</h4>
              </div>
              <div class="pt-15 pb-0 text-left text-title-2 natural--text text--darken-3">
                {{ $t("signin.label") }}
              </div>
              <div class="pt-0">
                <MobileTextField v-model="mobile" v-on:input="event = $event" />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                large
                block
                color="primary"
                class="text-subtitle-1"
                @click="signIn"
                :disabled="isDisable()"
                >{{ $t("signin.button") }}
                </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="network">
      <NetworkCard @onClose="network = false" />
    </v-dialog>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import MobileTextField from "@/components/signin/MobileTextField";
import NetworkCard from "@/components/common/NetworkCard";
import { otp } from "@/api/public";

export default {
  name: "SignIn",
  components: {
    MobileTextField,
    NetworkCard,
  },

  data() {
    return {
      network: false,
      mobile: "",
      loading:false,
      event: ""
    };
  },
  methods: {
    isDisable(){
      if(!this.event) return true
      if(this.event.length<12) return true
      return false
    },
    signIn() {
      if (this.isOnline) {
          this.loading = true

        otp(
          { data: { mobile: this.mobile.replace(/^(\+)|\D/g, "$1") } },
          (message) => {
            if (message.data.code === 1) {
             this.loading = false
             this.$router.replace({name: 'otp',params: {mobile: this.mobile }})
            }
            this.loading = false
            // this.$router.replace({ path: "otp/"+this.mobile });
          },
          (error) => {
            console.log(error);
          }
        );

        // make network request that returns 'userData' object
        //this.appData = userData
        //this.$offlineStorage.set('user', userData)
      } else {
        this.network = true;
        //this.appData = this.$offlineStorage.get('user')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(128, 160, 233, 0.3) !important;
    color: rgb(255, 255, 255) !important;
}
</style>