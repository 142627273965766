<template>
 
  <v-text-field
    v-bind="$attrs"
    v-on="inputListeners"
    :placeholder="$t('signin.placeholder')"
    required
    clearable
    v-mask="'###-###-####'"
    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
    autofocus
    type="tel"
    dense
    height="50"
    class="text-title-1"
    >

    <!-- <template slot="label">
      <div class="natural--text text--darken-3 text-title-2">
        {{ $t("signin.label") }}
      </div>
    </template> -->
  </v-text-field>

</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  props: ['title'],
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }}
};
</script>

<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-input input {
max-height: none;
}
</style>